import { AnyAction, Dispatch, ThunkDispatch, combineReducers, configureStore } from "@reduxjs/toolkit";
import sessionReducer from "./reducers/session";
import storage from 'redux-persist/lib/storage';
import { persistReducer, persistStore } from 'redux-persist';
import thunk from 'redux-thunk';

const persistConfig = {
  key: 'root',
  storage,
  // blacklist: ['session']
}

const rootReducer = combineReducers({
  'session': sessionReducer,
});

export const reducer = {
  sessionReducer,
};

const persistedReducer = persistReducer(persistConfig, rootReducer)

export const store = configureStore({
  reducer: persistedReducer,
  devTools: process.env.NODE_ENV !== 'production',
  middleware: [thunk]
})

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = Dispatch<AnyAction> &
  ThunkDispatch<RootState, null, AnyAction>;

export const persistor = persistStore(store)