import { Field, Formik } from "formik";
import * as yup from "yup";
import { Button, TextInput, FileUpload } from "../../../components";
import { Dropdown } from "../../../components/Forms/Dropdown/Dropdown";
import {
  ContentLayout,
  ContentLayoutSize,
} from "../../../components/Layouts/content";
import { useEffect, useState } from "react";
import { iImageSlider } from "../../../types/common";
import { api } from "../../../services/api";
import { useLocation, useNavigate, useParams } from "react-router";
import { useSnackbar } from "react-simple-snackbar";
import { ModalDelete } from "../../../components/Modal/ModalDelete";

export const ImageSliderForm = () => {
  const navigate = useNavigate();
  const params = useParams();
  const [loading, setLoading] = useState<boolean>(false);
  const [openSnackbar] = useSnackbar();
  const [imageSlider, setImageSlider] = useState<iImageSlider | undefined>(
    undefined
  );

  const validationSchema = yup.object().shape({
    title: yup.string().required("Judul harus terisi"),
    // position: yup.optional().required("Position harus terisi"),
    img_src: yup.mixed().required("Gambar is required"),
  });

  const __onSubmit = async (values: Partial<iImageSlider>) => {
    try {
      setLoading(true);

      if (params.id) {
        const payload: any = new FormData();
        payload.append("title", values.title);
        payload.append("position", values.position);
        payload.append("img_src", values.img_src);

        const response = await api().updateImageSlider(params.id, payload);

        if (response.err) {
          throw new Error("__onSubmit: updateImageSlider Error");
        }

        navigate(-1);
        openSnackbar("ImageSlider berhasil diubah");
      } else {
        // console.log(values);
        const payload: any = new FormData();
        payload.append("title", values.title);
        payload.append("position", values.position);
        payload.append("img_src", values.img_src);

        // for (let entry of payload.entries()) {
        //   console.log(entry[0] + ": " + entry[1]);
        // }
        const response = await api().createImageSlider(payload);

        if (response.err) {
          throw new Error("__onSubmit: createImageSlider Error");
        }

        navigate(-1);
        openSnackbar("ImageSlider berhasil dibuat");
      }
    } catch (e) {
      openSnackbar("ImageSlider gagal dibuat harap coba lagi");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    (async () => {
      if (params.id) {
        try {
          setLoading(true);
          const response = await api().getImageSlider(params.id);

          if (!response.err) {
            setImageSlider(response.data);
          }
        } catch (e) {
          openSnackbar("Gagal memuat imageSlider");
        } finally {
          setLoading(false);
        }
      }
    })();
  }, []);

  const __previewImage = (values: Partial<iImageSlider>) => {
    if (values.img_src) {
      let imgUrl = "";

      switch (typeof values.img_src) {
        case "object":
          // console.log(values.img_src)
          imgUrl = window.URL.createObjectURL(values.img_src);
          break;
        default:
          imgUrl = `${process.env.REACT_APP_API_BASE_URL}/${values.img_src}`;
      }

      return <img src={imgUrl} style={{ maxHeight: "100px" }} />;
    }

    return null;
  };

  if (loading) {
    return null;
  }

  return (
    <ContentLayout
      title={params.id ? "Edit Image Slider" : "Tambah Image Slider"}
      size={ContentLayoutSize.Half}
    >
      <Formik
        initialValues={{
          title: imageSlider?.title ?? "",
          position: imageSlider?.position ?? "",
          img_src: imageSlider?.img_src ?? null,
        }}
        onSubmit={__onSubmit}
        validationSchema={validationSchema}
      >
        {({ values, errors, handleChange, handleSubmit, setFieldValue }) => {
          return (
            <form className="flex flex-col w-full" onSubmit={handleSubmit}>
              <div className="flex flex-row pb-5 justify-center items-center">
                <div className="flex-col flex-1">
                  <div className="block">
                    <span className="block text-dark-navy font-medium text-sm mb-2">
                      Upload Gambar
                    </span>
                    <label
                      htmlFor="files"
                      className="py-3 px-6 text-sm bg-base-text rounded-lg text-white inline-block"
                    >
                      Upload Gambar
                    </label>
                    <input
                      id="files"
                      type="file"
                      className="hidden"
                      onChange={(event: any) => {
                        setFieldValue("img_src", event.currentTarget.files[0]);
                      }}
                    />
                  </div>
                </div>

                {__previewImage(values)}
              </div>
              <div className="flex-1">
                <TextInput
                  containerClass="pb-5"
                  label="Judul"
                  name="title"
                  value={values.title}
                  handleChange={handleChange}
                  error={errors.title}
                  disabled={loading}
                />
              </div>
              <div className="flex-1 w-1/2">
                <TextInput
                  containerClass="pb-5"
                  label="Position"
                  name="position"
                  type="number"
                  value={values.position}
                  handleChange={handleChange}
                  // error={errors.position}
                  disabled={loading}
                />
              </div>
              <div
                className="bg-light-grey w-full my-4"
                style={{ height: 1 }}
              ></div>

              <Button label="Simpan" type="submit" disabled={loading}></Button>
            </form>
          );
        }}
      </Formik>
    </ContentLayout>
  );
};
