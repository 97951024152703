import { Outlet, useNavigate } from "react-router-dom"
import GistaruLogo from '../../assets/images/ic-logo.svg'
import { Sidebar } from "../Sidebar/Sidebar"
import { useEffect } from "react"
import { useAuth } from "../../hooks/use-auth"

export const DefaultLayout = () => {
  const {session} = useAuth();
  const navigate = useNavigate()

  useEffect(() => {
    if (!session.user) {
      return navigate('/login')
    }
  }, [])

  return (
    <main className="flex flex-col w-full h-full font-TTNormsPro">
      {/* Navbar */}
      <div className="full-w h-20">
        <div className="flex items-center h-full">
          <img src={GistaruLogo} alt="Gistaru Logo" className="pl-3"/>
          <span className="pl-2 font-semibold">Aplikasi<br/>Dashboard Gistaru</span>
        </div>
      </div>
      {/* Sidebar */}
      <div className="flex flex-row h-full shadow-inner">
        <Sidebar />
        {/* Content */}
        <div className="h-full w-full pb-10">
          <Outlet />
        </div>
      </div>
    </main>
  )
}