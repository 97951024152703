import cx from "classnames";
import IconEdit from "../../../assets/images/ic-edit.svg";
import IconDelete from "../../../assets/images/ic-delete.svg";
import IconSend from "../../../assets/images/ic-send.svg";
import { CustomComponentStyle } from "../../../types/common";

export enum CellActionEnum {
  Edit,
  Delete,
  Send,
}

export interface iCellAction extends CustomComponentStyle {
  type: CellActionEnum;
  label: string;
  onClick: () => void;
}

export const CellAction = ({
  type,
  label,
  onClick,
  containerClass,
  containerStyle,
}: iCellAction) => {
  const __image = () => {
    switch (type) {
      case CellActionEnum.Edit:
        return <img src={IconEdit} />;
      case CellActionEnum.Delete:
        return <img src={IconDelete} />;
      case CellActionEnum.Send:
        return <img src={IconSend} />;
      default:
        return;
    }
  };

  // const __label = () => {
  //   return type === CellActionEnum.Edit ? "Edit" : "Hapus";
  //   switch (type) {
  //     case CellActionEnum.Edit:
  //       return <img src={IconEdit} />;
  //     case CellActionEnum.Delete:
  //       return <img src={IconDelete} />;
  //     case CellActionEnum.Send:
  //       return <img src={IconSend} />;
  //     default:
  //       return;
  //   }
  // };

  return (
    <div
      className={cx(
        "flex justify-center items-center cursor-pointer hover:bg-secondary px-3 py-2 rounded",
        ...(containerClass ?? "").split(" ")
      )}
      onClick={onClick}
      style={containerStyle ?? {}}
    >
      <div className="mr-1">{__image()}</div>
      {label}
    </div>
  );
};
