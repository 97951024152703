import { useAppDispatch, useAppSelector } from "./use-store";

export const useAuth = () => {
  const dispatch = useAppDispatch();
  const [session] = useAppSelector((state) => [state.session]);

  return {
    session
  }
};
