import Modal from "react-modal";
import { Button, ButtonStyleEnum } from "../Button/Button";

export interface ModalDeleteProps {
  isOpen: boolean
  onDismiss: () => void 
  onConfirm: () => void
  title: string
}

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
  },
};

export const ModalDelete = (props: ModalDeleteProps) => {
  
  return (
    <Modal
      isOpen={props.isOpen}
      // onAfterOpen={afterOpenModal}
      onRequestClose={props.onDismiss}
      contentLabel="Example Modal"
      // className="rounded"
      style={customStyles}
    >
      <div className="px-8">
        <h2 className="pt-10">Anda yakin ingin menghapus data {props.title} ini?</h2>
        <div className="flex flex-row pt-10">
          <Button
          style={ButtonStyleEnum.Secondary}
            containerClass="mr-2"
            label="Batal"
            onPress={props.onDismiss}
          />
          <Button
          containerClass="ml-2"
            label="Hapus"
            onPress={() => props.onConfirm()}
          />
        </div>
      </div>
      
    </Modal>
  );
};
