import { Formik } from "formik";
import * as yup from "yup";
import { useSnackbar } from "react-simple-snackbar";
import { useEffect, useState } from "react";

import { useLocation, useNavigate, useParams } from "react-router";
import { Button, TextInput } from "../../../components";
import { Dropdown } from "../../../components/Forms/Dropdown/Dropdown";
import {
  ContentLayout,
  ContentLayoutSize,
} from "../../../components/Layouts/content";
import { iArtikel } from "../../../types/common";
import { api } from "../../../services/api";
import { ModalDelete } from "../../../components/Modal/ModalDelete";
import { WysiwygEditor } from "../../../components/Forms/WysiwygEditor/WysiwygEditor";

export const ArtikelForm = () => {
  const navigate = useNavigate();
  const params = useParams();
  const [loading, setLoading] = useState<boolean>(false);
  const [openSnackbar] = useSnackbar();
  const [artikel, setArtikel] = useState<iArtikel | undefined>(undefined);

  const validationSchema = yup.object().shape({
    title: yup.string().required("Judul harus terisi"),
    short_desc: yup.string().required("Short deskripsi harus terisi"),
    desc: yup.string().required("Deskripsi harus terisi"),
    img_src: yup.mixed().required("Gambar is required"),
    type: yup.string().required("Type harus diisi"),
    status: yup.boolean().required("Status harus diisi"),
  });

  const __onSubmit = async (values: Partial<iArtikel>) => {
    try {
      setLoading(true);

      if (params.id) {
        const payload: any = new FormData();
        payload.append("title", values.title);
        payload.append("short_desc", values.short_desc);
        payload.append("desc", values.desc);
        payload.append("type", values.type);
        payload.append("img_src", values.img_src);
        payload.append("status", values.status);

        const response = await api().updateArtikel(params.id, payload);

        if (response.err) {
          throw new Error("__onSubmit: updateArtikel Error");
        }

        navigate(-1);
        openSnackbar("Artikel berhasil diubah");
      } else {
        const payload: any = new FormData();
        payload.append("title", values.title);
        payload.append("short_desc", values.short_desc);
        payload.append("desc", values.desc);
        payload.append("type", values.type);
        payload.append("img_src", values.img_src);
        payload.append("status", values.status);

        // for (let entry of payload.entries()) {
        //   console.log(entry[0] + ": " + entry[1]);
        // }

        const response = await api().createArtikel(payload);

        if (response.err) {
          throw new Error("__onSubmit: createArtikel Error");
        }

        navigate(-1);
        openSnackbar("Artikel berhasil dibuat");
      }
    } catch (e) {
      openSnackbar("Artikel gagal dibuat harap coba lagi");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    (async () => {
      if (params.id) {
        // console.log(`da`, params)
        try {
          setLoading(true);
          const response = await api().getArtikel(params.id);

          // console.log(`testt aritkel `, JSON.stringify(response.data, null, 2))

          if (!response.err) {
            setArtikel(response.data);
          }
        } catch (e) {
          openSnackbar("Gagal memuat artikel");
        } finally {
          setLoading(false);
        }
      }
    })();
  }, []);

  const __previewImage = (values: Partial<iArtikel>) => {
    if (values.img_src) {
      let imgUrl = "";

      switch (typeof values.img_src) {
        case "object":
          // console.log(values.img_src)
          imgUrl = window.URL.createObjectURL(values.img_src);
          break;
        default:
          imgUrl = `${process.env.REACT_APP_API_BASE_URL}/${values.img_src}`;
      }

      return <img src={imgUrl} style={{ maxHeight: "100px" }} />;
    }

    return null;
  };

  if (loading) {
    return null;
  }

  return (
    <ContentLayout
      title={params.id ? "Edit Artikel" : "Tambah Artikel"}
      size={ContentLayoutSize.Half}
    >
      <Formik
        initialValues={{
          title: artikel?.title ?? "",
          short_desc: artikel?.short_desc,
          type: artikel?.type,
          desc: artikel?.desc ?? "",
          img_src: artikel?.img_src,
          status: artikel?.status
        }}
        onSubmit={__onSubmit}
        validationSchema={validationSchema}
      >
        {({ values, errors, handleChange, handleSubmit, setFieldValue }) => {
          console.log(typeof values.img_src);

          return (
            <form className="flex flex-col w-full" onSubmit={handleSubmit}>
              <div className="flex flex-row pb-5 justify-center items-center">
                <div className="flex-col flex-1">
                  <div className="block">
                    <span className="block text-dark-navy font-medium text-sm mb-2">
                      Upload Gambar
                    </span>
                    <label
                      htmlFor="files"
                      className="py-3 px-6 text-sm bg-base-text rounded-lg text-white inline-block"
                    >
                      Upload Gambar
                    </label>
                    <input
                      id="files"
                      type="file"
                      className="hidden"
                      onChange={(event: any) => {
                        setFieldValue("img_src", event.currentTarget.files[0]);
                      }}
                    />
                  </div>
                </div>

                {__previewImage(values)}
              </div>

              <div className="flex-1">
                <TextInput
                  containerClass="pb-5"
                  label="Judul"
                  name="title"
                  value={values.title}
                  handleChange={handleChange}
                  error={errors.title}
                  disabled={loading}
                />
              </div>
              <div className="flex-1">
                <Dropdown<string>
                  value={values.type}
                  containerClass="pb-5"
                  label="Type"
                  name="type"
                  handleChange={handleChange}
                  onChangeValue={(value) => setFieldValue("type", value)}
                  error={errors.type}
                  disabled={loading}
                  options={[
                    {
                      label: "Berita",
                      value: "berita",
                    },
                    {
                      label: "Media",
                      value: "media",
                    },
                  ]}
                />
              </div>
              <div className="flex-1">
                <TextInput
                  containerClass="pb-5"
                  label="Deskripsi Singkat"
                  name="short_desc"
                  value={values.short_desc}
                  handleChange={handleChange}
                  error={errors.short_desc}
                  disabled={loading}
                />
              </div>
              <div className="flex-1 pb-5">
                <WysiwygEditor
                  label={"Deskripsi"}
                  value={values.desc}
                  setFieldValue={(val) => setFieldValue("desc", val)}
                />
                {/* <TextInput
                  containerClass="pb-5"
                  label="Deskripsi"
                  name="desc"
                  value={values.desc}
                  handleChange={handleChange}
                  error={errors.desc}
                  disabled={loading}
                /> */}
              </div>
              <div className="flex-1">
                <Dropdown<Boolean>
                  value={values.status}
                  containerClass="pb-5"
                  label="Status"
                  name="status"
                  handleChange={handleChange}
                  onChangeValue={(value) => setFieldValue("status", value)}
                  error={errors.status}
                  disabled={loading}
                  options={[
                    {
                      label: "Aktif",
                      value: true,
                    },
                    {
                      label: "Tidak Aktif",
                      value: false,
                    },
                  ]}
                />
              </div>
              <div className="w-full my-4" style={{ height: 1 }}></div>

              <Button label="Simpan" type="submit" disabled={loading}></Button>
            </form>
          );
        }}
      </Formik>
    </ContentLayout>
  );
};
