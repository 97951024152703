import { BaseComponentProps, iImageSlider } from "../../types/common";
import { ContentLayout } from "../../components/Layouts/content";
import { useState, useCallback, useEffect, SetStateAction } from "react";
import DataTable from "react-data-table-component";
import { useNavigate } from "react-router-dom";
import { useSnackbar } from "react-simple-snackbar";
import { Button } from "../../components";
import { ModalDelete } from "../../components/Modal/ModalDelete";
import { CellAction, CellActionEnum } from "../../components/Table";
import { api } from "../../services/api";

export const ImageSlider = () => {
  const navigate = useNavigate();
  const [openSnackbar] = useSnackbar();
  const [loading, setLoading] = useState<boolean>(false);
  const [pageSize, setPageSize] = useState<number>(10);
  const [imageSliders, setImageSliders] = useState<any[]>([]);
  const [meta, setMeta] = useState<
    { total: number; page: number; lastPage: number } | undefined
  >();
  const [pendingDelete, setPendingDelete] = useState<string | undefined>(
    undefined
  );

  const fetchImageSliders = useCallback(
    async (page = 1) => {
      try {
        setLoading(true);
        const response = await api().getImageSliders(page, pageSize);

        if (response.data) {
          setImageSliders(response.data.results);
          setMeta(response.data.meta);
        }
      } catch (e) {
        console.error(e);
      } finally {
        setLoading(false);
      }
    },
    [pageSize]
  );

  const handlePageChange = (page: number) => {
    console.log("handlePageChange", page);
    fetchImageSliders(page);
  };

  const handlePerRowsChange = async (newPerPage: number, page: number) => {
    console.log("handlePerRowsChange", newPerPage, page);
    setPageSize(newPerPage);
    fetchImageSliders(page);
  };

  // @ts-ignore
  const columns: TableColumn<iImageSlider>[] = [
    {
      name: "No",
      selector: (row: any, index: any) => (index ?? 0) + 1,
      width: "60px",
    },
    {
      name: "Gambar",
      // selector: (row: { img_src: any }) => ,
      cell: (row: { img_src: SetStateAction<string | undefined> }) => <img height="40px" src={`${process.env.REACT_APP_API_BASE_URL}/${row.img_src}`} />,
      style: {
        padding: '10px 0'
      }
    },
    {
      name: "Judul",
      selector: (row: { title: any }) => row.title,
    },
    ,
    {
      name: "Posisi",
      selector: (row: { position: number }) => row.position,
    },
    ,
    {
      name: "Action",
      cell: (row: { id: SetStateAction<string | undefined> }) => {
        return (
          <div className="flex flex-row w-full">
            <CellAction
              containerClass="mr-1"
              label="Edit"
              type={CellActionEnum.Edit}
              onClick={() => navigate(`/image-slider/${row.id}/edit`)}
            />
            <CellAction
              containerClass="ml-1"
              label="Delete"
              type={CellActionEnum.Delete}
              onClick={() => {
                setPendingDelete(row.id);
              }}
            />
          </div>
        );
      },
    },
  ];

  const __confirmDelete = async () => {
    try {
      if (!pendingDelete) {
        return;
      }

      setLoading(true);
      const response = await api().deleteImageSlider(pendingDelete);

      if (response.err) {
        openSnackbar(`ImageSlider ${pendingDelete} gagal dihapus`);
        return;
      }

      openSnackbar(`ImageSlider ${pendingDelete} telah berhasil dihapus`);
      setPendingDelete(undefined);
      fetchImageSliders();
    } catch (e) {
    } finally {
      setLoading(false);
      setPendingDelete(undefined);
    }
  };

  useEffect(() => {
    fetchImageSliders();
  }, []);

  return (
    <ContentLayout
      title="Image Slider"
      renderButton={() => (
        <Button
          size="sm"
          label="Add Image Slider"
          onPress={() => navigate("/image-slider/new")}
        ></Button>
      )}
    >
      <DataTable
        columns={columns}
        data={imageSliders}
        pagination
        paginationServer
        progressPending={loading}
        onChangePage={handlePageChange}
        onChangeRowsPerPage={handlePerRowsChange}
        paginationPerPage={pageSize}
        paginationTotalRows={meta?.total ?? 0}
        // dense
      />

      <ModalDelete
        isOpen={typeof pendingDelete !== "undefined"}
        onDismiss={() => setPendingDelete(undefined)}
        onConfirm={() => __confirmDelete()}
        title="image slider"
      />
    </ContentLayout>
  );
};
