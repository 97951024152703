import { BaseComponentProps } from "../../types/common";
import cx from "classnames";
import { ContentLayout } from "../../components/Layouts/content";
import { Donut } from "../../components/Charts/Donut";
import { useCallback, useEffect, useMemo, useState } from "react";
import _ from 'lodash'
import numeral from 'numeral'
import { api } from "../../services/api";

export interface iDonutLegend {
  label: string;
  value: number;
  color: string;
}

export const DonutLegends = ({ values }: { values: iDonutLegend[] }) => {

  const getTotal = useMemo(() => {
    return values.reduce((o, d) => d.value + o, 0)
  }, [values.map(d => d.value)])

  const getPercent = useCallback((value: number) => {
    return (value/getTotal * 100).toFixed(2);
  }, [values])

  return (
    <div className="flex flex-row justify-between mt-2 mr-4">
      {values.map(({ label, value, color }) => (
        <div className="flex flex-row" key={label}>
          <div
            style={{
              width: 10,
              height: 10,
              borderRadius: 10,
              backgroundColor: color,
              marginTop: 5,
              marginRight: 8,
            }}
          ></div>
          <div className="flex flex-col">
            <span className="text-sm mb-1">{label}</span>
            <span className="text-xs font-semibold" style={{ color }}>{ numeral(value).format('0,0')} ({getPercent(value)}%)</span>
          </div>
        </div>
      ))}
    </div>
  );
};

export const data = {
  labels: ["Registered", "Unregistered"],
  datasets: [
    {
      data: [87.06, 12.94],
      backgroundColor: ["#5FD16A", "#D2D2D2"],
      borderWidth: 1,
    },
  ],
};

export const Dashboard = ({ children }: BaseComponentProps) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [dashboards, setDashboards] = useState<any[]>([])

  const fetchDashboards = useCallback(
    async (page = 1) => {
      try {
        setLoading(true);
        const response = await api().getDashboard();

        if (response.data) {
          // console.log(`tesss`, response.data)
          setDashboards(response.data);
        }
      } catch (e) {
        console.error(e);
      } finally {
        setLoading(false);
      }
    },
    []
  );

  useEffect(() => {
    fetchDashboards();
  }, []);


  // const [values] = useState<any[]>([
  //   {
  //     title: "Statistik Pengguna",
  //     data: [{
  //       label: "Register",
  //       color: "#5FD16A",
  //       value: 324473,
  //     },
  //     {
  //       label: "Unregistered",
  //       color: "#D2D2D2",
  //       value: 48223,
  //     }]
  //   },
  //   {
  //     title: "Statistic Usage Devices",
  //     data: [{
  //       label: "Android",
  //       color: "#FEC32F",
  //       value: 552322,
  //     },
  //     {
  //       label: "iOS",
  //       color: "#D2D2D2",
  //       value: 233442,
  //     }]
  //   }
  // ])
  

  return (
    <ContentLayout
      title="Halo, Admin Panel"
      subtitle="Selamat Datang di Aplikasi Dashboard Gistaru"
      transparent
      compact
    >
      <div className="flex justify-between flex-row gap-4">
        { dashboards.map((value: any) => {
          return (
            <div className="bg-white rounded-lg w-full p-6" key={value.title}>
              <span className="text-sm font-semibold">{ value.title }</span>

              <DonutLegends
                values={value.data}
              />

              <Donut data={value.data} primaryColor={_.first(value.data.map((d: any) => d.color))} />
            </div>
          )
        })}
      </div>
    </ContentLayout>
  );
};
