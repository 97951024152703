import { HTMLInputTypeAttribute } from "react";
import cx from "classnames";
import { CustomComponentStyle } from "../../../types/common";

interface TextInputProps<T> extends CustomComponentStyle {
  label: string;
  name: string;
  placeholder?: string;
  type?: HTMLInputTypeAttribute;
  handleChange?: (ChangeEvent: any) => void;
  handleBlur?: () => void;
  value?: any;
  disabled?: boolean;
  error?: string;
}

export function TextInput<T>(props: TextInputProps<T>) {
  return (
    <div className={cx(...(props.containerClass ?? "").split(" "))}>
      <label className="block text-dark-navy font-medium text-sm mb-2">
        {props.label}
      </label>
      <input
        className={cx(
          "shadow border border-light-grey rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline text-sm",
          {
            "border-third": Boolean(props.error),
          }
        )}
        type={props.type}
        placeholder={props.placeholder ?? props.label}
        onChange={props.handleChange}
        onBlur={props.handleBlur}
        value={props.value}
        name={props.name}
        disabled={props.disabled}
      />
      {props.error && <span className="text-third text-sm">{props.error}</span>}
    </div>
  );
}
