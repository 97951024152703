import { BaseComponentProps, iArtikel } from "../../types/common";
import { ContentLayout } from "../../components/Layouts/content";
import { useState, useCallback, useEffect, SetStateAction } from "react";
import DataTable from "react-data-table-component";
import { useNavigate } from "react-router-dom";
import { useSnackbar } from "react-simple-snackbar";
import { Button } from "../../components";
import { ModalDelete } from "../../components/Modal/ModalDelete";
import { CellAction, CellActionEnum, CellStatus } from "../../components/Table";
import { api } from "../../services/api";

export const ArtikelGistaru = () => {
  const navigate = useNavigate();
  const [openSnackbar] = useSnackbar();
  const [loading, setLoading] = useState<boolean>(false);
  const [pageSize, setPageSize] = useState<number>(10);
  const [artikels, setArtikels] = useState<any[]>([]);
  const [meta, setMeta] = useState<
    { total: number; page: number; lastPage: number } | undefined
  >();
  const [pendingDelete, setPendingDelete] = useState<string | undefined>(
    undefined
  );

  const fetchArtikelGistaru = useCallback(
    async (page = 1) => {
      try {
        setLoading(true);
        const response = await api().getArtikelGistaru(page, pageSize);

        if (response.data) {
          // console.log(response.data)
          setArtikels(response.data.results);
          setMeta(response.data.meta);
        }
      } catch (e) {
        console.error(e);
      } finally {
        setLoading(false);
      }
    },
    [pageSize]
  );

  const handlePageChange = (page: number) => {
    console.log("handlePageChange", page);
    fetchArtikelGistaru(page);
  };

  const handlePerRowsChange = async (newPerPage: number, page: number) => {
    console.log("handlePerRowsChange", newPerPage, page);
    setPageSize(newPerPage);
    fetchArtikelGistaru(page);
  };

  // @ts-ignore
  const columns: TableColumn<iArtikel>[] = [
    {
      name: "No",
      selector: (row: any, index: any) => (index ?? 0) + 1,
      width: "60px"    
    },
    {
      name: "Gambar",
      // selector: (row: { img_src: any }) => ,
      cell: (row: { img_src: SetStateAction<string | undefined> }) => {
        return (
          <img height="40px" src={`${row.img_src}`} style={{ height: '80px'}} />
        )
      },
      style: {
        padding: '10px 0'
      },
      width: "120px"
    },
    {
      name: "Judul",
      selector: (row: { title: any }) => row.title,
      cell: (row: any) => {
        return (
          <p dangerouslySetInnerHTML={{ __html: row.title }} />
        )
      },
      wrap: true,
    },
    {
      name: "Deskripsi Singkat",
      selector: (row: { short_desc: string }) => row.short_desc,
      cell: (row: any) => {
        return (
          <p className="content-ellipsis" dangerouslySetInnerHTML={{ __html: row.short_desc }} />
        )
      },
      wrap: true,
    },
    {
      name: "Tipe",
      selector: (row: { type: string }) => row.type,
      wrap: true,
    },
    {
      name: "Status",
      selector: (row: any) => row.status,
      cell: (row: any) => {
        return <CellStatus status={row.status} />;
      },
      style: {
        textAlign: "center",
      },
      width: "100px"
    },
    // {
    //   name: "Action",
    //   cell: (row: { id: SetStateAction<string | undefined> }) => {
    //     return (
    //       <div className="flex flex-row w-full">
    //         <CellAction
    //           containerClass="mr-1"
    //           label="Edit"
    //           type={CellActionEnum.Edit}
    //           onClick={() => navigate(`/artikel/${row.id}/edit`)}
    //         />
    //         <CellAction
    //           containerClass="ml-1"
    //           label="Delete"
    //           type={CellActionEnum.Delete}
    //           onClick={() => {
    //             setPendingDelete(row.id);
    //           }}
    //         />
    //       </div>
    //     );
    //   },
    // },
  ];

  const __confirmDelete = async () => {
    try {
      if (!pendingDelete) {
        return;
      }

      setLoading(true);
      const response = await api().deleteArtikel(pendingDelete);

      if (response.err) {
        openSnackbar(`Artikel ${pendingDelete} gagal dihapus`);
        return;
      }

      openSnackbar(`Artikel ${pendingDelete} telah berhasil dihapus`);
      setPendingDelete(undefined);
      fetchArtikelGistaru();
    } catch (e) {
    } finally {
      setLoading(false);
      setPendingDelete(undefined);
    }
  };

  useEffect(() => {
    fetchArtikelGistaru();
  }, []);

  return (
    <ContentLayout
      title="Artikel Gistaru"
      // renderButton={() => (
      //   <Button
      //     size="sm"
      //     label="Add Artikel"
      //     onPress={() => navigate("/artikel/new")}
      //   ></Button>
      // )}
    >
      <DataTable
        columns={columns}
        data={artikels}
        pagination
        paginationServer
        progressPending={loading}
        onChangePage={handlePageChange}
        onChangeRowsPerPage={handlePerRowsChange}
        paginationPerPage={pageSize}
        paginationTotalRows={meta?.total ?? 0}
        // dense
      />

      <ModalDelete
        isOpen={typeof pendingDelete !== "undefined"}
        onDismiss={() => setPendingDelete(undefined)}
        onConfirm={() => __confirmDelete()}
        title="artikel"
      />
    </ContentLayout>
  );
};
