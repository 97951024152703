import cx from 'classnames'

export const CellStatus = ({ status }: { status: boolean }) => {
  return (
    <div className="flex justify-center items-center">
      <span
        style={{ width: 8, height: 8, display: "inline-block" }}
        className={cx("rounded mr-1", {
          'bg-error': !status,
          'bg-green-leaf': status,
        })}
      ></span>
      {status ? "Aktif" : "Tidak Aktif"}
    </div>
  );
};