import { useNavigate } from "react-router-dom";
import * as yup from "yup";
import { useAppDispatch } from "../../hooks/use-store";
import { setSession, setUser } from "../../store/reducers/session";
import GistaruLogo from "../../assets/images/ic-logo.svg";
import "./Login.scss";
import { useState } from "react";
import { Button, TextInput } from "../../components";
import { Formik } from "formik";
import { api } from "../../services/api";
import { AxiosError } from "axios";

export const Login = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const [apiErrors, setApiErrors] = useState<string[]>([]);
  const [loading, setLoading] = useState<boolean>(false);

  const __onLogin = async (values: { username: string; password: string }) => {
    try {
      setLoading(true);
      setApiErrors([]);

      const response = await api().login(values);
      dispatch(
        setSession({
          access_token: response.data?.access_token,
          user: response.data?.user,
        })
      );
      navigate("/");
    } catch (e) {
      if (e instanceof AxiosError) {
        setApiErrors(e.response?.data.message);
      }
    } finally {
      setLoading(false);
    }
  };

  const validationSchema = yup.object().shape({
    username: yup.string().required("Username tidak boleh kosong"),
    password: yup.string().required("Password tidak boleh kosong"),
  });

  return (
    <main className="w-full h-full bg-dark-navy justify-center items-center flex font-TTNormsPro">
      <Formik
        onSubmit={__onLogin}
        initialValues={{
          username: "",
          password: "",
        }}
        validationSchema={validationSchema}
      >
        {({
          values,
          errors,
          handleChange,
          handleBlur,
          handleSubmit,
          ...rest
        }) => {
          return (
            <form
              className="flex justify-center items-center xs:w-full sm:w-9/12 md:w-1/3 bg-white rounded-xl flex-col p-8"
              onSubmit={handleSubmit}
            >
              <div className="w-1/3">
                <img src={GistaruLogo} width="100%" />
              </div>

              <div className="flex justify-center flex-col items-center my-8">
                <h4 className="font-semibold text-lg">Login</h4>
                <span className="text-dark-navy font-medium opacity-40">
                  Aplikasi Dashboard Gistaru
                </span>
              </div>

              {apiErrors && apiErrors.length > 0 && (
                <div className="w-full">
                  <ul className="bg-error px-4 py-2 rounded-md mb-4 text-white text-sm">
                    {apiErrors.map((error, index) => (
                      <li key={index.toString()}>{error}</li>
                    ))}
                  </ul>
                </div>
              )}

              {/* <button onClick={__onLogin}>Login</button> */}
              <div className="mb-4 w-full">
                <TextInput
                  label="Username"
                  name="username"
                  handleChange={handleChange}
                  disabled={loading}
                  error={errors.username}
                />
              </div>
              <div className="mb-4 w-full">
                <TextInput
                  label="Password"
                  name="password"
                  type="password"
                  handleChange={handleChange}
                  disabled={loading}
                  error={errors.password}
                />
              </div>

              <div className="mt-4 w-full">
                <Button label="Login" type="submit" disabled={loading} />
              </div>
            </form>
          );
        }}
      </Formik>
    </main>
  );
};
