import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { iUser } from "../../types/common";

export interface iSessionReducer {
  user?: iUser;
  access_token?: string;
}

const initialState: iSessionReducer = {
  user: undefined,
  access_token: undefined,
};

const slice = createSlice({
  name: "session",
  initialState,
  reducers: {
    setSession: (state, action: PayloadAction<{ user?: iUser, access_token?: string }>) => {
      console.log('Setting session', action.payload)
      state.user = action.payload.user
      state.access_token = action.payload.access_token
    },
    setUser: (state, action: PayloadAction<iUser>) => {
      state.user = action.payload;
    },
    setToken: (state, action: PayloadAction<string>) => {
      state.access_token = action.payload;
    },
    clearSession: (state) => {
      state.user = initialState.user
      state.access_token = initialState.access_token
    }
  },
});

export const { setSession, setUser, setToken, clearSession } = slice.actions;

export default slice.reducer;
