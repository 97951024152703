import { Chart as ChartJS, ArcElement, Tooltip, ChartOptions } from "chart.js";
import { useCallback, useMemo, useState } from "react";
import { Doughnut } from "react-chartjs-2";
import cx from 'classnames'
import _ from 'lodash'
import numeral from 'numeral'

ChartJS.register(ArcElement, Tooltip);

export interface iDonusProps {
  data: any;
  primaryColor?: string;
}

export function Donut({ data, primaryColor = '#d2d2d2' }: any) {
  const [values] = useState<any>({
      labels: data.map((d: any) => d.label),
      datasets: [
        {
          data: data.map((d: any) => d.value),
          backgroundColor: data.map((d: any) => d.color),
          borderWidth: 1,
        },
      ]
  })
  
  const [options] = useState<ChartOptions<any>>({
    plugins: {
      legend: {
        display: false,
      },
    },
    aspectRatio: 1,
    cutout: "88%",
    radius: "90%",
    elements: {
      arc: {
        spacing: 4,
        borderRadius: 15,
      },
    },
  });

  const max = useMemo(() => {
    return _.max<number>((data ?? [0]).map((d: any) => d.value)) ?? 0
  }, [])

  const total = useMemo(() => {
    return _.reduce(data, (total, d: any) => total + d.value, 0)
  }, [])

  return (
    <div
      className="mt-4 relative"
      style={{ width: "100%", aspectRatio: 1 / 1 }}
    >
      <Doughnut data={values} options={options} />
      <div
        className="absolute"
        style={{ top: "calc(50% - 50px)", left: "calc(50% - 100px)" }}
      >
        <div
          className="flex flex-col text-center items-center justify-center"
          style={{ width: "200px", height: 100 }}
        >
          <span className="text-6xl font-semibold" style={{ color: primaryColor }}>
            { (max/total*100).toFixed(2) }%
          </span>
          <span className="font-semibold">
            <span>{ numeral(max).format('0,0') }</span> /{" "}
            <span className={cx(`text-[#d2d2d2]`)}>{ numeral(total).format('0,0') }</span>
          </span>
        </div>
      </div>
    </div>
  );
}
