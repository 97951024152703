import axios, { AxiosError, AxiosInstance, AxiosResponse } from "axios";
import { iBaseResponse, iArtikel, iMenu, iNotifikasi, iPaginatedResponse, iUser, iImageSlider, iPengguna } from "../types/common";

export const api = () => {
  const __axios = axios.create({
    baseURL: process.env.REACT_APP_API_BASE_URL,
  });

  __axios.interceptors.response.use(response => {
    if (process.env.NODE_ENV !== 'production') {
      console.log(`
=========================================
onResponse
=========================================
Method: ${(response.config.method ?? '').toUpperCase()}
Url: ${response.config.baseURL}/${response.config.url}
Status: ${response.status}
Response: ${JSON.stringify(response.data)}
=========================================
EOF onResponse
=========================================
`);
    }

    return response;
  });

  __axios.interceptors.request.use((config) => {
    const authToken = "";

    if (authToken) {
      config.headers.Authorization = `Bearer ${authToken}`;
    }

    if (process.env.NODE_ENV !== "production") {
      console.log(`
=========================================
onRequest
=========================================
Method: ${(config.method ?? "").toUpperCase()}
Url: ${config.baseURL}/${config.url}
Headers: ${JSON.stringify(config.headers, null, 2)}
Payload: ${JSON.stringify(config.data, null, 2)}
=========================================
EOF Request
=========================================
`);
    }

    return config;
  });

  function __onResponse<T>(response: AxiosResponse): iBaseResponse<T> {
    if ([201, 200].includes(response.status)) {
      return response.data as iBaseResponse<T>;
    }

    throw new Error('__onResponse: Error!')
  }

  const login = async (data: { username: string, password: string }) => {
    const response = await __axios.post('/api/user/auth', data)
    return __onResponse<{ user: iUser, access_token: string }>(response);
  }

  // Menu

  const getMenus = async (page = 1, pageSize = 10) => {
    const response = await __axios.get(`/api/menu/list-all?page=${page}&pageSize=${pageSize}`)
    return __onResponse<iPaginatedResponse<iMenu>>(response);
  }

  const createMenu = async (data: Partial<iMenu>) => {
    const response = await __axios.post(`/api/menu/register`, data)
    return __onResponse<iMenu>(response);
  }

  const getMenu = async (id: string) => {
    const response = await __axios.get(`/api/menu/${id}`)
    return __onResponse<iMenu>(response);
  }

  const updateMenu = async (id: string, data: Partial<iMenu>) => {
    const response = await __axios.post(`/api/menu/${id}/edit`, data)
    return __onResponse<iMenu>(response);
  }

  const deleteMenu = async (id: string) => {
    const response = await __axios.post(`/api/menu/${id}/delete`)
    return __onResponse<iMenu>(response);
  }

  // Notifikasi

  const getNotifications = async (page = 1, pageSize = 10) => {
    const response = await __axios.get(`/api/notifikasi/list-all?page=${page}&pageSize=${pageSize}`)
    return __onResponse<iPaginatedResponse<iNotifikasi>>(response);
  }

  const createNotifikasi = async (data: Partial<iNotifikasi>) => {
    const response = await __axios.post(`/api/notifikasi/register`, {
      ...data,
    })
    return __onResponse<iNotifikasi>(response);
  }

  const getNotifikasi = async (id: string) => {
    const response = await __axios.get(`/api/notifikasi/${id}`)
    return __onResponse<iNotifikasi>(response);
  }

  const updateNotifikasi = async (id: string, data: Partial<iNotifikasi>) => {
    const response = await __axios.post(`/api/notifikasi/${id}/edit`, data)
    return __onResponse<iNotifikasi>(response);
  }

  const pushNotifikasi = async (id: string) => {
    const response = await __axios.post(`/api/notifikasi/${id}/push-notif`)
    return __onResponse<iNotifikasi>(response);
  }

  const deleteNotifikasi = async (id: string) => {
    const response = await __axios.post(`/api/notifikasi/${id}/delete`)
    return __onResponse<iNotifikasi>(response);
  }

  // Artikel

  const getArtikels = async (page = 1, pageSize = 10) => {
    const response = await __axios.get(`/api/artikel/list-all?page=${page}&pageSize=${pageSize}`)
    return __onResponse<iPaginatedResponse<iArtikel>>(response);
  }

  const getArtikelGistaru = async (page = 1, pageSize = 10, type = 'external') => {
    const response = await __axios.get(`/api/artikel/list-all?page=${page}&pageSize=${pageSize}&type=${type}`)
    return __onResponse<iPaginatedResponse<iArtikel>>(response);
  }

  const createArtikel = async (data: any) => {
    const response = await __axios.post(`/api/artikel/register`, data)
    return __onResponse<iArtikel>(response);
  }

  const getArtikel = async (id: string) => {
    const response = await __axios.get(`/api/artikel/${id}`)
    return __onResponse<iArtikel>(response);
  }

  const updateArtikel = async (id: string, data: any) => {
    const response = await __axios.post(`/api/artikel/${id}/edit`, data)
    return __onResponse<iArtikel>(response);
  }

  const deleteArtikel = async (id: string) => {
    const response = await __axios.post(`/api/artikel/${id}/delete`)
    return __onResponse<iArtikel>(response);
  }

  // Image Slider

  const getImageSliders = async (page = 1, pageSize = 10) => {
    const response = await __axios.get(`/api/slider/list-all?page=${page}&pageSize=${pageSize}`)
    return __onResponse<iPaginatedResponse<iImageSlider>>(response);
  }

  const createImageSlider = async (data: any) => {
    const response = await __axios.post(`/api/slider/register`, data)
    return __onResponse<iImageSlider>(response);
  }

  const getImageSlider = async (id: string) => {
    const response = await __axios.get(`/api/slider/${id}`)
    return __onResponse<iImageSlider>(response);
  }

  const updateImageSlider = async (id: string, data: any) => {
    const response = await __axios.post(`/api/slider/${id}/edit`, data)
    return __onResponse<iImageSlider>(response);
  }

  const deleteImageSlider = async (id: string) => {
    const response = await __axios.post(`/api/slider/${id}/delete`)
    return __onResponse<iNotifikasi>(response);
  }

  // Pengguna

  const getUsers = async (page = 1, pageSize = 10) => {
    const response = await __axios.get(`/api/user/list-all?page=${page}&pageSize=${pageSize}`)
    return __onResponse<iPaginatedResponse<iPengguna>>(response);
  }

  const getDashboard = async () => {
    const response = await __axios.get(`/api/dashboard/pengguna/stat`)
    // return __onResponse<iPaginatedResponse<iPengguna>>(response);
    return __onResponse<any>(response);
  }


  return {
    login,
    getDashboard,
    createMenu,
    getMenus,
    getMenu,
    updateMenu,
    deleteMenu,
    getNotifications,
    createNotifikasi,
    getNotifikasi,
    updateNotifikasi,
    deleteNotifikasi,
    getArtikels,
    getArtikelGistaru,
    getArtikel,
    createArtikel,
    updateArtikel,
    deleteArtikel,
    getImageSliders,
    getImageSlider,
    createImageSlider,
    updateImageSlider,
    deleteImageSlider,
    getUsers,
    pushNotifikasi
  };
};
