import { Navigate, Route, Routes } from "react-router-dom";
import { Login } from "./pages/Login/Login";
import { DefaultLayout } from "./components/Layouts";
import { Dashboard } from "./pages/Dashboard/Dashboard";
import { Menu } from "./pages/Menu/Menu";
import { MenuForm } from "./pages/Menu/MenuForm/MenuForm";
import { Notifikasi } from "./pages/Notifikasi/Notifikasi";
import { ImageSlider } from "./pages/ImageSlider/ImageSlider";
import { Artikel } from "./pages/Artikel/Artikel";
import { Pengguna } from "./pages/Pengguna/Pengguna";
import { NotifikasiForm } from "./pages/Notifikasi/NotifikasiForm/NotifikasiForm";
import { ArtikelForm } from "./pages/Artikel/ArtikelForm/ArtikelForm";
import { ImageSliderForm } from "./pages/ImageSlider/ImageSliderForm/ImageSliderForm";
import { ArtikelGistaru } from "./pages/ArtikelGistaru/ArtikelGistaru";

export const RootNavigation = () => {
  return (
    <Routes>
      <Route path="/login" element={<Login />} />
      <Route element={<DefaultLayout />}>
        <Route path="/" element={<Navigate to="/dashboard" replace />} />
        
        <Route path="/dashboard" element={<Dashboard />} />

        <Route path="/menu" element={<Menu />} />
        <Route path="/menu/new" element={<MenuForm />} />
        <Route path="/menu/:id/edit" element={<MenuForm />} />

        <Route path="/pengguna" element={<Pengguna />} />
        
        <Route path="/artikel" element={<Artikel />} />
        <Route path="/artikel/new" element={<ArtikelForm />} />
        <Route path="/artikel/:id/edit" element={<ArtikelForm />} />

        <Route path="/artikel-gistaru" element={<ArtikelGistaru />} />
        
        <Route path="/image-slider" element={<ImageSlider />} />
        <Route path="/image-slider/new" element={<ImageSliderForm />} />
        <Route path="/image-slider/:id/edit" element={<ImageSliderForm />} />

        <Route path="/notifikasi" element={<Notifikasi />} />
        <Route path="/notifikasi/new" element={<NotifikasiForm />} />
        <Route path="/notifikasi/:id/edit" element={<NotifikasiForm />} />
      </Route>
    </Routes>
  );
};
