import cx from 'classnames'
import Select from 'react-select'
import { CustomComponentStyle } from "../../../types/common"

export interface DropdownProps<T> extends CustomComponentStyle {
  label: string;
  name: string;
  options: {label: string, value: T}[]
  placeholder?: string;
  handleChange?: (ChangeEvent: any) => void
  handleBlur?: () => void
  value?: T;
  disabled?: boolean;
  error?: string
  onChangeValue?: (value?: T) => void
}

export function Dropdown<T>(props: DropdownProps<T>) {
  return (
    <div className={cx(...(props.containerClass ?? "").split(" "))}>
      <label className="block text-dark-navy font-medium text-sm mb-2">
        {props.label}
      </label>  
      <Select 
        value = {
          props.options.filter(option => 
            option.value === props.value)
        }
        // @ts-ignore
        options={props.options}
        classNames={{
          control: (state) => 'border-light-grey',
        }}
        // @ts-ignore
        onChange={(opt) => props.onChangeValue?.(opt?.value)}
      />
      { props.error && (
        <span className="text-third text-sm">{ props.error }</span>
      )}
    </div>
  )
}