import { Formik } from "formik";
import * as yup from "yup";
import { Button, TextInput } from "../../../components";
import { Dropdown } from "../../../components/Forms/Dropdown/Dropdown";
import {
  ContentLayout,
  ContentLayoutSize,
} from "../../../components/Layouts/content";
import { useEffect, useState } from "react";
import { iNotifikasi } from "../../../types/common";
import { api } from "../../../services/api";
import { useLocation, useNavigate, useParams } from "react-router";
import { useSnackbar } from "react-simple-snackbar";
import { ModalDelete } from "../../../components/Modal/ModalDelete";
import { WysiwygEditor } from "../../../components/Forms/WysiwygEditor/WysiwygEditor";

export const NotifikasiForm = () => {
  const navigate = useNavigate();
  const params = useParams();
  const [loading, setLoading] = useState<boolean>(false);
  const [openSnackbar] = useSnackbar();
  const [notifikasi, setNotifikasi] = useState<iNotifikasi | undefined>(undefined);

  const validationSchema = yup.object().shape({
    title: yup.string().required("Judul harus terisi"),
    short_desc: yup.string().required("Short deskripsi harus terisi"),
    desc: yup.string().required("Deskripsi harus terisi"),
  });

  const __onSubmit = async (values: Partial<iNotifikasi>) => {
    try {
      setLoading(true);

      if (params.id) {
        const response = await api().updateNotifikasi(params.id, values);

        if (response.err) {
          throw new Error("__onSubmit: updateNotifikasi Error");
        }

        navigate(-1);
        openSnackbar("Notifikasi berhasil diubah");
      } else {
        const response = await api().createNotifikasi(values);

        if (response.err) {
          throw new Error("__onSubmit: createNotifikasi Error");
        }

        navigate(-1);
        openSnackbar("Notifikasi berhasil dibuat");
      }
    } catch (e) {
      openSnackbar("Notifikasi gagal dibuat harap coba lagi");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    (async () => {
      if (params.id) {
        try {
          setLoading(true);
          const response = await api().getNotifikasi(params.id);

          if (!response.err) {
            setNotifikasi(response.data);
          }
        } catch (e) {
          openSnackbar("Gagal memuat notifikasi");
        } finally {
          setLoading(false);
        }
      }
    })();
  }, []);

  if (loading) {
    return null;
  }

  return (
    <ContentLayout
      title={params.id ? "Edit Notifikasi" : "Tambah Notifikasi"}
      size={ContentLayoutSize.Half}
    >
      <Formik
        initialValues={{
          title: notifikasi?.title ?? "",
          short_desc: notifikasi?.short_desc,
          desc: notifikasi?.desc ?? "",
        }}
        onSubmit={__onSubmit}
        validationSchema={validationSchema}
      >
        {({ values, errors, handleChange, handleSubmit, setFieldValue }) => {
          return (
            <form className="flex flex-col w-full" onSubmit={handleSubmit}>
              <div className="flex flex-row">
                <div className="flex flex-col pr-5 w-1/2">
                  <TextInput
                    containerClass="pb-5"
                    label="Judul"
                    name="title"
                    value={values.title}
                    handleChange={handleChange}
                    error={errors.title}
                    disabled={loading}
                  />
                </div>
                <div className="flex flex-col w-1/2">
                  <TextInput
                    containerClass="pb-5"
                    label="Deskripsi Singkat"
                    name="short_desc"
                    value={values.short_desc}
                    handleChange={handleChange}
                    error={errors.short_desc}
                    disabled={loading}
                  />
                </div>
              </div>
              <div className="flex-1">
                <WysiwygEditor
                  label={'Deskripsi'}
                  value={values.desc}
                  setFieldValue={(val) => setFieldValue("desc", val)}
                />
              </div>
              <div
                className="bg-light-grey w-full my-4"
                style={{ height: 1 }}
              ></div>

              <Button label="Simpan" type="submit" disabled={loading}></Button>
            </form>
          );
        }}
      </Formik>
    </ContentLayout>
  );
};
