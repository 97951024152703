import SnackbarProvider from 'react-simple-snackbar'
import logo from './assets/images/logo.svg';
import { Provider } from 'react-redux'
import { persistor, store } from './store/index';
import { PersistGate } from 'redux-persist/integration/react';
import './App.scss';
import { RootNavigation } from './RootNavigation';
import { BrowserRouter, HashRouter } from 'react-router-dom';

function App() {
  // useEffect(() => {
  //   ;(async () => {
  //     await persistor.purge()
  //     console.log('data purged!')
  //   })()
  // }, [])

  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <BrowserRouter basename="/mobile">
          <SnackbarProvider>
            <RootNavigation />
          </SnackbarProvider>
        </BrowserRouter>
      </PersistGate>
    </Provider>
  );
}

export default App;
