import { Formik } from "formik";
import * as yup from "yup";
import { Button, TextInput } from "../../../components";
import { Dropdown } from "../../../components/Forms/Dropdown/Dropdown";
import {
  ContentLayout,
  ContentLayoutSize,
} from "../../../components/Layouts/content";
import { useEffect, useState } from "react";
import { iMenu } from "../../../types/common";
import { api } from "../../../services/api";
import { useLocation, useNavigate, useParams } from "react-router";
import { useSnackbar } from "react-simple-snackbar";
import { ModalDelete } from "../../../components/Modal/ModalDelete";

export const MenuForm = () => {
  const navigate = useNavigate();
  const params = useParams();
  const [loading, setLoading] = useState<boolean>(false);
  const [openSnackbar] = useSnackbar();
  const [menu, setMenu] = useState<iMenu | undefined>(undefined);

  const validationSchema = yup.object().shape({
    nama_menu: yup.string().required("Nama menu harus terisi"),
    type: yup.string().required("Type harus terisi"),
    status: yup.boolean().required("Status harus terisi"),
    position: yup.string().required("Position harus terisi"),
  });

  const __onSubmit = async (values: Partial<iMenu>) => {
    try {
      setLoading(true);

      if (params.id) {
        const payload: any = new FormData();
        payload.append("nama_menu", values.nama_menu);
        payload.append("type", values.type);
        payload.append("status", values.status);
        payload.append("link", values.link);
        payload.append("icon_path", values.icon_path);
        payload.append("position", values.position);

        const response = await api().updateMenu(params.id, payload);

        if (response.err) {
          throw new Error("__onSubmit: updateMenu Error");
        }

        navigate(-1);
        openSnackbar("Menu berhasil diubah");
      } else {
        const payload: any = new FormData();
        payload.append("nama_menu", values.nama_menu);
        payload.append("type", values.type);
        payload.append("status", values.status);
        payload.append("link", values.link);
        payload.append("icon_path", values.icon_path);
        payload.append("position", values.position);

        const response = await api().createMenu(payload);

        if (response.err) {
          throw new Error("__onSubmit: createMenu Error");
        }

        navigate(-1);
        openSnackbar("Menu berhasil dibuat");
      }
    } catch (e) {
      openSnackbar("Menu gagal dibuat harap coba lagi");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    (async () => {
      if (params.id) {
        try {
          setLoading(true);
          const response = await api().getMenu(params.id);

          if (!response.err) {
            setMenu(response.data);
          }
        } catch (e) {
          openSnackbar("Gagal memuat menu");
        } finally {
          setLoading(false);
        }
      }
    })();
  }, []);

  const __previewImage = (values: Partial<iMenu>) => {
    if (values.icon_path) {
      let imgUrl = "";

      switch (typeof values.icon_path) {
        case "object":
          // console.log(values.icon_path)
          imgUrl = window.URL.createObjectURL(values.icon_path);
          break;
        default:
          imgUrl = `${process.env.REACT_APP_API_BASE_URL}/${values.icon_path}`;
      }

      return <img src={imgUrl} style={{ maxHeight: "100px" }} />;
    }

    return null;
  };

  if (loading) {
    return null;
  }

  return (
    <ContentLayout
      title={params.id ? "Edit Menu" : "Tambah Menu"}
      size={ContentLayoutSize.Half}
    >
      <Formik
        initialValues={{
          nama_menu: menu?.nama_menu ?? "",
          status: menu?.status,
          link: menu?.link ?? "",
          icon_path: menu?.icon_path,
          position: menu?.position,
          type: menu?.type,
        }}
        onSubmit={__onSubmit}
        validationSchema={validationSchema}
      >
        {({ values, errors, handleChange, handleSubmit, setFieldValue }) => {
          console.log(values);
          return (
            <form className="flex flex-col w-full" onSubmit={handleSubmit}>
              <div className="flex flex-row pb-5 justify-center items-center">
                <div className="flex-col flex-1">
                  <div className="block">
                    <span className="block text-dark-navy font-medium text-sm mb-2">
                      Upload Icon
                    </span>
                    <label
                      htmlFor="files"
                      className="py-3 px-6 text-sm bg-base-text rounded-lg text-white inline-block"
                    >
                      Upload Icon
                    </label>
                    <input
                      id="files"
                      type="file"
                      className="hidden"
                      onChange={(event: any) => {
                        setFieldValue(
                          "icon_path",
                          event.currentTarget.files[0]
                        );
                      }}
                    />
                  </div>
                </div>

                {__previewImage(values)}
              </div>
              <div className="flex flex-row">
                <div className="flex flex-col pr-5 w-1/2">
                  <TextInput
                    containerClass="pb-5"
                    label="Nama Menu"
                    name="nama_menu"
                    value={values.nama_menu}
                    handleChange={handleChange}
                    error={errors.nama_menu}
                    disabled={loading}
                  />
                  <Dropdown<Boolean>
                    value={values.status}
                    containerClass="pb-5"
                    label="Status"
                    name="status"
                    handleChange={handleChange}
                    onChangeValue={(value) => setFieldValue("status", value)}
                    error={errors.status}
                    disabled={loading}
                    options={[
                      {
                        label: "Aktif",
                        value: true,
                      },
                      {
                        label: "Tidak Aktif",
                        value: false,
                      },
                    ]}
                  />
                  <TextInput
                    containerClass="pb-5"
                    label="Position"
                    name="position"
                    type="number"
                    value={values.position}
                    handleChange={handleChange}
                    error={errors.position}
                    disabled={loading}
                  />
                </div>
                <div className="flex flex-col w-1/2">
                  <TextInput
                    containerClass="pb-5"
                    label="Link"
                    name="link"
                    value={values.link}
                    handleChange={handleChange}
                    error={errors.link}
                    disabled={loading}
                  />
                  <Dropdown<string>
                    value={values.type}
                    containerClass="pb-5"
                    label="Type"
                    name="type"
                    handleChange={handleChange}
                    onChangeValue={(value) => setFieldValue("type", value)}
                    error={errors.type}
                    disabled={loading}
                    options={[
                      {
                        label: "Webview",
                        value: "webview",
                      },
                      {
                        label: "Native",
                        value: "native",
                      },
                    ]}
                  />
                </div>
              </div>
              <div
                className="bg-light-grey w-full my-4"
                style={{ height: 1 }}
              ></div>

              <Button label="Simpan" type="submit" disabled={loading}></Button>
            </form>
          );
        }}
      </Formik>
    </ContentLayout>
  );
};
