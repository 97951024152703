import React from 'react';
import { useFormikContext } from 'formik';

// @ts-ignore
export const FileUpload = ({ name }) => {
  const { setFieldValue } = useFormikContext();

  const handleChange = (event: any) => {
    const file = event.target.files[0];
    console.log(`apa ini isinya`, name)
    setFieldValue(name, file);
  };

  return (
    <div>
      <input
        type="file"
        name={name}
        onChange={handleChange}
      />
    </div>
  );
};

