import "./Sidebar.scss";
import cx from "classnames";

import IconDashboard from "../../assets/images/ic-dashboard.svg";
import IconMenu from "../../assets/images/ic-menu.svg";
import IconUser from "../../assets/images/ic-user.svg";
import IconLogout from "../../assets/images/ic-logout.svg";
import IconMedia from "../../assets/images/ic-media.svg";
import IconImage from "../../assets/images/ic-image-slider.svg";
import IconNotif from "../../assets/images/ic-notifikasi.svg";
import { NavLink, useNavigate } from "react-router-dom";
import { useAppDispatch } from "../../hooks/use-store";
import { clearSession } from "../../store/reducers/session";

type SidebarItemProps = {
  text: string;
  to?: string;
  onClick?: () => void;
  icon?: string;
};

const SidebarItem = ({ text, to, icon, onClick }: SidebarItemProps) => {
  if (!to) {
    return (
      <li
      onClick={onClick}
      className={cx(
        "flex flex-row items-center py-3 px-5 sidebar-menu-item",
      )}
    >
      <img src={icon} className="pr-3" width={32} style={{ color: "red" }} />
      <span>{text}</span>
    </li>
    )
  }

  return (
    <NavLink
      to={to!}
      className={cx(
        "flex flex-row items-center py-3 px-5 sidebar-menu-item",
        {}
      )}
    >
      <img src={icon} className="pr-3" width={32} style={{ color: "red" }} />
      <span>{text}</span>
    </NavLink>
  );
};

export const Sidebar = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  return (
    <div className="h-full w-64">
      <ul>
        <SidebarItem text="Dashboard" icon={IconDashboard} to="/" />
        <SidebarItem text="Menu" icon={IconMenu} to="/menu" />
        <SidebarItem text="Pengguna" icon={IconUser} to="/pengguna" />
        <SidebarItem text="Berita & Media" icon={IconMedia} to="/artikel" />
        <SidebarItem text="Artikel Gistaru" icon={IconMedia} to="/artikel-gistaru" />
        <SidebarItem text="Image Slider" icon={IconImage} to="/image-slider" />
        <SidebarItem text="Notifikasi" icon={IconNotif} to="/notifikasi" />
        <SidebarItem
          text="Keluar"
          icon={IconLogout}
          onClick={() => {
            dispatch(clearSession())
            navigate('/login')
          }}
        />
      </ul>
    </div>
  );
};
