import DataTable, { TableColumn } from "react-data-table-component";
import { ContentLayout } from "../../components/Layouts/content";
import { SetStateAction, useCallback, useEffect, useState } from "react";
import { api } from "../../services/api";
import { iMenu } from "../../types/common";
import { CellAction, CellActionEnum, CellStatus } from "../../components/Table";
import { Button } from "../../components";
import { useNavigate } from "react-router";
import { ModalDelete } from "../../components/Modal/ModalDelete";
import { useSnackbar } from "react-simple-snackbar";

export const Menu = () => {
  const navigate = useNavigate();
  const [openSnackbar] = useSnackbar();
  const [loading, setLoading] = useState<boolean>(false);
  const [pageSize, setPageSize] = useState<number>(10);
  const [menus, setMenus] = useState<any[]>([]);
  const [meta, setMeta] = useState<
    { total: number; page: number; lastPage: number } | undefined
  >();
  const [pendingDelete, setPendingDelete] = useState<string | undefined>(
    undefined
  );

  const fetchMenus = useCallback(
    async (page = 1) => {
      try {
        setLoading(true);
        const response = await api().getMenus(page, pageSize);

        if (response.data) {
          setMenus(response.data.results);
          setMeta(response.data.meta);
        }
      } catch (e) {
        console.error(e);
      } finally {
        setLoading(false);
      }
    },
    [pageSize]
  );

  const handlePageChange = (page: number) => {
    console.log("handlePageChange", page);
    fetchMenus(page);
  };

  const handlePerRowsChange = async (newPerPage: number, page: number) => {
    console.log("handlePerRowsChange", newPerPage, page);
    setPageSize(newPerPage);
    fetchMenus(page);
  };

  // @ts-ignore
  const columns: TableColumn<iMenu>[] = [
    {
      name: "No",
      selector: (row, index) => (index ?? 0) + 1,
      width: "60px",
    },
    {
      name: "Icon",
      // selector: (row: { img_src: any }) => ,
      cell: (row: { icon_path: SetStateAction<string | undefined> }) => {
        return (
          <img
            height="40px"
            src={`${row.icon_path}`}
            style={{ height: "80px" }}
          />
        );
      },
      style: {
        padding: "10px 0",
      },
      width: "120px",
    },
    {
      name: "Nama Menu",
      selector: (row) => row.nama_menu,
    },
    ,
    {
      name: "Link",
      // selector: (row) => row.link,
      cell: (row) => {
        return (
          <a
            className="text-blue-600 text-blue-500 underline"
            href={row.link}
            target="_blank"
          >
            {row.link}
          </a>
        );
      },
    },
    {
      name: "Posisi",
      selector: (row: { position: string }) => row.position,
    },
    ,
    {
      name: "Status",
      selector: (row) => row.status,
      cell: (row) => {
        return <CellStatus status={row.status} />;
      },
      style: {
        textAlign: "center",
      },
      width: "100px",
    },
    ,
    {
      name: "Action",
      cell: (row) => {
        return (
          <div className="flex flex-row w-full">
            <CellAction
              containerClass="mr-1"
              label="Edit"
              type={CellActionEnum.Edit}
              onClick={() => navigate(`/menu/${row.id}/edit`)}
            />
            <CellAction
              containerClass="ml-1"
              label="Delete"
              type={CellActionEnum.Delete}
              onClick={() => {
                setPendingDelete(row.id);
              }}
            />
          </div>
        );
      },
    },
  ];

  const __confirmDelete = async () => {
    try {
      if (!pendingDelete) {
        return;
      }

      setLoading(true);
      const response = await api().deleteMenu(pendingDelete);

      if (response.err) {
        openSnackbar(`Menu ${pendingDelete} gagal dihapus`);
        return;
      }

      openSnackbar(`Menu ${pendingDelete} telah berhasil dihapus`);
      setPendingDelete(undefined);
      fetchMenus();
    } catch (e) {
    } finally {
      setLoading(false);
      setPendingDelete(undefined);
    }
  };

  useEffect(() => {
    fetchMenus();
  }, []);

  return (
    <ContentLayout
      title="Menu"
      renderButton={() => (
        <Button
          size="sm"
          label="Add Menu"
          onPress={() => navigate("/menu/new")}
        ></Button>
      )}
    >
      <DataTable
        columns={columns}
        data={menus}
        pagination
        paginationServer
        progressPending={loading}
        onChangePage={handlePageChange}
        onChangeRowsPerPage={handlePerRowsChange}
        paginationPerPage={pageSize}
        paginationTotalRows={meta?.total ?? 0}
        // dense
      />

      <ModalDelete
        isOpen={typeof pendingDelete !== "undefined"}
        onDismiss={() => setPendingDelete(undefined)}
        onConfirm={() => __confirmDelete()}
        title="menu"
      />
    </ContentLayout>
  );
};
