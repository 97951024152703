import { BaseComponentProps, iPengguna } from "../../types/common";
import { ContentLayout } from "../../components/Layouts/content";
import { useState, useCallback, useEffect, SetStateAction } from "react";
import DataTable from "react-data-table-component";
import { useNavigate } from "react-router-dom";
import { useSnackbar } from "react-simple-snackbar";
import { Button } from "../../components";
import { ModalDelete } from "../../components/Modal/ModalDelete";
import { CellAction, CellActionEnum } from "../../components/Table";
import { api } from "../../services/api";

export const Pengguna = () => {
  const navigate = useNavigate();
  const [openSnackbar] = useSnackbar();
  const [loading, setLoading] = useState<boolean>(false);
  const [pageSize, setPageSize] = useState<number>(10);
  const [notifications, setNotifications] = useState<any[]>([]);
  const [meta, setMeta] = useState<
    { total: number; page: number; lastPage: number } | undefined
  >();
  const [pendingDelete, setPendingDelete] = useState<string | undefined>(
    undefined
  );

  const fetchNotifications = useCallback(
    async (page = 1) => {
      try {
        setLoading(true);
        const response = await api().getUsers(page, pageSize);

        if (response.data) {
          setNotifications(response.data.results);
          setMeta(response.data.meta);
        }
      } catch (e) {
        console.error(e);
      } finally {
        setLoading(false);
      }
    },
    [pageSize]
  );

  const handlePageChange = (page: number) => {
    console.log("handlePageChange", page);
    fetchNotifications(page);
  };

  const handlePerRowsChange = async (newPerPage: number, page: number) => {
    console.log("handlePerRowsChange", newPerPage, page);
    setPageSize(newPerPage);
    fetchNotifications(page);
  };

  // @ts-ignore
  const columns: TableColumn<iPengguna>[] = [
    {
      name: "No",
      selector: (row: any, index: any) => (index ?? 0) + 1,
    },
    {
      name: "Nama",
      selector: (row: { name: any }) => row.name,
    },
    ,
    {
      name: "Username",
      selector: (row: { username: string }) => row.username,
    },
    {
      name: "E-mail",
      selector: (row: { email: string }) => row.email,
    },
    {
      name: "Role",
      selector: (row: { role: string }) => row.role,
    },
    ,
    // {
    //   name: "Action",
    //   cell: (row: { id: SetStateAction<string | undefined> }) => {
    //     return (
    //       <div className="flex flex-row w-full">
    //         <CellAction
    //           containerClass="mr-1"
    //           type={CellActionEnum.Edit}
    //           onClick={() => navigate(`/pengguna/${row.id}/edit`)}
    //         />
    //         <CellAction
    //           containerClass="ml-1"
    //           type={CellActionEnum.Delete}
    //           onClick={() => {
    //             setPendingDelete(row.id);
    //           }}
    //         />
    //       </div>
    //     );
    //   },
    // },
  ];

  // const __confirmDelete = async () => {
  //   try {
  //     if (!pendingDelete) {
  //       return;
  //     }

  //     setLoading(true);
  //     const response = await api().deletePengguna(pendingDelete);

  //     if (response.err) {
  //       openSnackbar(`Pengguna ${pendingDelete} gagal dihapus`);
  //       return;
  //     }

  //     openSnackbar(`Pengguna ${pendingDelete} telah berhasil dihapus`);
  //     setPendingDelete(undefined);
  //     fetchNotifications();
  //   } catch (e) {
  //   } finally {
  //     setLoading(false);
  //     setPendingDelete(undefined);
  //   }
  // };

  useEffect(() => {
    fetchNotifications();
  }, []);

  return (
    <ContentLayout
      title="Pengguna"
      // renderButton={() => (
      //   <Button
      //     size="sm"
      //     label="Add Pengguna"
      //     onPress={() => navigate("/pengguna/new")}
      //   ></Button>
      // )}
    >
      <DataTable
        columns={columns}
        data={notifications}
        pagination
        paginationServer
        progressPending={loading}
        onChangePage={handlePageChange}
        onChangeRowsPerPage={handlePerRowsChange}
        paginationPerPage={pageSize}
        paginationTotalRows={meta?.total ?? 0}
        // dense
      />

      {/* <ModalDelete
        isOpen={typeof pendingDelete !== "undefined"}
        onDismiss={() => setPendingDelete(undefined)}
        onConfirm={() => __confirmDelete()}
        title="pengguna"
      /> */}
    </ContentLayout>
  );
};
