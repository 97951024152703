import cx from 'classnames'
import { BaseComponentProps } from "../../types/common";
import { Button } from "../Button/Button";

export enum ContentLayoutSize {
  Full,
  Half,
}

interface Props extends BaseComponentProps {
  title: string;
  subtitle?: string;
  size?: ContentLayoutSize;
  renderButton?: () => React.ReactNode
  transparent?: boolean,
  compact?: boolean,
}

export const ContentLayout = ({ title, subtitle, children, renderButton, size = ContentLayoutSize.Full, transparent = false, compact = false,}: Props) => {
  return (
    <div className="h-full items-center flex flex-col overflow-y-auto" style={{ backgroundColor: "#D9D9D9", position: 'relative', zIndex: 0 }}>
      <div className={cx("flex flex-row justify-between pt-10 pb-5", {
        "w-full px-8": size === ContentLayoutSize.Full,
        "w-2/3": size === ContentLayoutSize.Half
      })}>
        <div className="font text-2xl font-semibold text-white flex justify-between flex-col">
          <h2 className="block">{title}</h2>
          { !!subtitle && <h3>{ subtitle }</h3> }
        </div>

        { !!renderButton && (
          <div className="w-auto">
            { renderButton() }
          </div>
        )}
      </div>
      <div
        className="w-full"
        style={{
          top: 0,
          position: "absolute",
          backgroundColor: "#051F32",
          height: "12rem",
          zIndex: -1,
        }}
      ></div>
      <div className={cx({
        "w-full px-8": size === ContentLayoutSize.Full,
        "w-2/3 mb-5": size === ContentLayoutSize.Half
      })}>
        <div className={cx({"bg-white": !transparent, "p-8": !compact}, "rounded-xl")}>
          {/* @ts-ignore */}
          {children}
        </div>
      </div>
    </div>
  );
};
