import cx from 'classnames'
import { CustomComponentStyle } from '../../types/common';

export enum ButtonStyleEnum {
  Primary,
  Secondary,
}

interface ButtonProps extends CustomComponentStyle {
  label: string;
  type?: 'submit' | 'reset' | 'button';
  onPress?: () => void;
  disabled?: boolean;
  size?: 'sm' | 'md' | 'lg'
  style?: ButtonStyleEnum
}

export const Button = ({ label, onPress, type, disabled, size = 'md', style, ...rest }: ButtonProps) => {
  return (
    <button
      type={type}
      className={cx("bg-secondary py-3 px-4 w-full rounded-md font-semibold disabled:bg-light-grey", {
        'text-sm': size === 'sm',
        'text-md': size === 'md',
        'text-lg': size === 'lg',
        'bg-secondary': style === ButtonStyleEnum.Primary,
        'bg-transparent': style === ButtonStyleEnum.Secondary,
      }, ...((rest.containerClass ?? "").split(" ")))}
      onClick={onPress}
      disabled={disabled}
    >
      {label}
    </button>
  );
};
