import React from "react"
import { Navigate, Outlet } from "react-router-dom";
import { useAuth } from "../../hooks/use-auth";

export const DashboardLayout = () => {
  const { session } = useAuth();

  if (!session) {
    return <Navigate to="/login" replace />;
  }

  return (
    <>
      <Outlet />
    </>
  )
}