import { useEffect, useState } from "react";
import { Editor } from "react-draft-wysiwyg";
import { convertToRaw, ContentState, EditorState } from "draft-js";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";

import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

interface IWysiwygEditorProps {
  value?: string;
  setFieldValue: (val: string) => void;
  label: string;
}

export const WysiwygEditor = ({ value, setFieldValue, label }: IWysiwygEditorProps) => {
  const prepareDraft = (value: string) => {
    const draft = htmlToDraft(value);
    const contentState = ContentState.createFromBlockArray(draft.contentBlocks);
    const editorState = EditorState.createWithContent(contentState);
    return editorState;
  };

  const [editorState, setEditorState] = useState(
    value ? prepareDraft(value) : EditorState.createEmpty()
  );

  const onEditorStateChange = (editorState: EditorState) => {
    const forFormik = draftToHtml(
      convertToRaw(editorState.getCurrentContent())
    );

    setFieldValue(forFormik);
    setEditorState(editorState);
  };

  return (
    <div>
      <label className="block text-dark-navy font-medium text-sm mb-2"> {label} </label>
      <Editor
        toolbar={{
          options: [
            'inline', 'blockType', 'fontSize', 'list', 'textAlign', 'colorPicker', 'link', 'embedded', 'image', 'remove'
          ],
          list: { inDropdown: true },
          textAlign: { inDropdown: true },
          link: { inDropdown: true },
          history: { inDropdown: true },
        }}
        editorState={editorState}
        toolbarClassName="toolbarClassName"
        wrapperClassName="editor-wrapper shadow border border-light-grey rounded p-2"
        editorClassName="editorClassName"
        onEditorStateChange={onEditorStateChange}
        
      />
    </div>
  )
}